import {
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";
import { useNavigation } from "contexts/NavigationContext";
import useFashionPreference from "hooks/useFashionPreference";
import { ProductCategory } from "models/ProductCategory.model";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC, Fragment } from "react";
import { SECTION_ROOT } from "utils/config";

export type CategoryVerticalMenuProps =
	{
		category?: ProductCategory;
		parentCategories?: ProductCategory[];
	};

const CategoryVerticalMenu: FC<
	CategoryVerticalMenuProps
> = ({
	category,
	parentCategories,
}) => {
	const { mainSections } =
		useNavigation();
	const { fashionPreference } =
		useFashionPreference();
	const currentPathname =
		usePathname() ?? "";

	const tree = fashionPreference?.id
		? parentCategories?.length === 0
			? {
					...fashionPreference,
					category_children:
						fashionPreference.category_children ??
						[],
			  }
			: fashionPreference
		: {
				...category,
				category_children:
					mainSections ?? [],
		  };

	const isActiveItem = (
		itemHandle: string
	) =>
		currentPathname.includes(
			`${SECTION_ROOT}/${itemHandle}`
		);

	const isAncestorActive = (
		children
	) => {
		return children.some(
			(child) =>
				isActiveItem(child.handle) ||
				(child.category_children &&
					isAncestorActive(
						child.category_children
					))
		);
	};

	const renderChildren = (
		children,
		depth = 1
	) => {
		const paddingLeftValue =
			3 + depth * 3;

		return children.map((child) => {
			const isActive = isActiveItem(
				child.handle
			);
			let shouldRenderChildren =
				child.category_children &&
				(isActive ||
					isAncestorActive(
						child.category_children
					));

			return (
				<Fragment key={child.handle}>
					<ListItem
						sx={{
							paddingLeft:
								paddingLeftValue,
						}}
						disablePadding
						disableGutters>
						<ListItemButton
							href={`${SECTION_ROOT}/${child.handle}${window.location.search}`}
							LinkComponent={Link}>
							<ListItemText
								primary={child.name}
								primaryTypographyProps={{
									fontWeight: isActive
										? "700"
										: undefined,
								}}
							/>
						</ListItemButton>
					</ListItem>
					{shouldRenderChildren &&
						renderChildren(
							child.category_children,
							depth + 1
						)}
				</Fragment>
			);
		});
	};

	return (
		<List>
			{renderChildren(
				tree.category_children,
				0
			)}
		</List>
	);
};

export default CategoryVerticalMenu;
