import {
	Box,
	BoxProps,
} from "@mui/material";
import { layoutConstant } from "utils/constants";

const TopbarWrapper = (
	props: BoxProps & { bgColor: string }
) => {
	// delete bgColor from props
	const { bgColor, children, ...rest } =
		props;
	return (
		<Box
			{...rest}
			sx={{
				...rest.sx,
				fontSize: 12,
				width: "100%",
				height:
					layoutConstant.topbarHeight,
				color: "secondary.contrastText",
				background: (theme) =>
					bgColor ||
					theme.palette.grey[900],
				transition: "height 300ms ease",
				"& .menuItem": {
					minWidth: 100,
				},
				"& .marginRight": {
					marginRight: "1.25rem",
				},
				"& .expand": {
					display: "none",
					padding: 0,
				},
				"& .handler": {
					height:
						layoutConstant.topbarHeight,
				},
				"& .menuTitle": {
					fontSize: 12,
					marginLeft: "0.5rem",
					fontWeight: 600,
				},
				display: {
					xs: "none",
					lg: "block",
				},
			}}>
			{children}
		</Box>
	);
};

export default TopbarWrapper;
