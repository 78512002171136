import {
	Card,
	CardProps,
} from "@mui/material";
import { FC } from "react";

// ===============================================
interface BazaarCardProps
	extends CardProps {
	// hoverEffect?: boolean;
}
// ===============================================

const BazaarCard: FC<
	BazaarCardProps
> = ({
	// hoverEffect,
	children,
	...rest
}) => (
	<Card
		elevation={0}
		{...rest}>
		{children}
	</Card>
);

export default BazaarCard;
