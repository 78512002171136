import { SvgIcon, SvgIconProps } from "@mui/material";

const Search = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.3162 20.0056L17.2879 15.9891C18.5876 14.3333 19.2928 12.2886 19.2902 10.1836C19.2902 8.30891 18.7343 6.47636 17.6928 4.91764C16.6513 3.35892 15.1709 2.14405 13.439 1.42665C11.707 0.709246 9.80123 0.521542 7.96259 0.887269C6.12396 1.253 4.43507 2.15573 3.10948 3.48131C1.7839 4.80689 0.88117 6.49579 0.515443 8.33442C0.149715 10.1731 0.33742 12.0788 1.05482 13.8108C1.77222 15.5428 2.98709 17.0231 4.54581 18.0646C6.10453 19.1061 7.93709 19.662 9.81174 19.662C11.9167 19.6646 13.9615 18.9594 15.6173 17.6597L19.6338 21.688C19.7439 21.7991 19.8749 21.8872 20.0193 21.9474C20.1637 22.0075 20.3186 22.0385 20.475 22.0385C20.6314 22.0385 20.7862 22.0075 20.9306 21.9474C21.075 21.8872 21.206 21.7991 21.3162 21.688C21.4272 21.5779 21.5154 21.4468 21.5755 21.3024C21.6357 21.1581 21.6666 21.0032 21.6666 20.8468C21.6666 20.6904 21.6357 20.5355 21.5755 20.3912C21.5154 20.2468 21.4272 20.1157 21.3162 20.0056ZM2.70292 10.1836C2.70292 8.77758 3.11985 7.40316 3.90098 6.23412C4.6821 5.06508 5.79235 4.15393 7.09132 3.61588C8.39028 3.07783 9.81963 2.93705 11.1986 3.21134C12.5776 3.48564 13.8443 4.16269 14.8384 5.15688C15.8326 6.15106 16.5097 7.41773 16.784 8.79671C17.0583 10.1757 16.9175 11.605 16.3794 12.904C15.8414 14.203 14.9302 15.3132 13.7612 16.0943C12.5922 16.8755 11.2177 17.2924 9.81174 17.2924C7.92637 17.2924 6.11821 16.5434 4.78505 15.2103C3.45189 13.8771 2.70292 12.0689 2.70292 10.1836Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Search;
