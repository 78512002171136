import { Region } from "models/Region.model";
import { formatAmount } from "utils/formatAmount";

interface GetAmountProps {
	amount: number | null | undefined;
	includeTaxes: boolean;
	region: Region | null | undefined;
	locale?: string;
	maximumFractionDigits?: number;
	minimumFractionDigits?: number;
}

export const getFormattedAmount = ({
	amount,
	includeTaxes,
	region,
	locale = "fr-FR",
	maximumFractionDigits,
	minimumFractionDigits,
}: GetAmountProps) => {
	if (!region) return "";

	const formatedamount = formatAmount({
		amount: amount || 0,
		region: region,
		includeTaxes: includeTaxes,
		locale,
		maximumFractionDigits,
		minimumFractionDigits,
	});

	return formatedamount;
};
