import React, {
	createContext,
	useCallback,
	useContext,
	useState,
} from "react";

import connectClearRefinements, {
	ClearRefinementsConnectorParams,
	ClearRefinementsRenderState,
	ClearRefinementsWidgetDescription,
} from "instantsearch.js/es/connectors/clear-refinements/connectClearRefinements";
import type {
	RangeConnectorParams,
	RangeRenderState,
	RangeWidgetDescription,
} from "instantsearch.js/es/connectors/range/connectRange";
import connectRange from "instantsearch.js/es/connectors/range/connectRange";
// import connectRefinementList from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
// import connectToggleRefinement from "instantsearch.js/es/connectors/toggle-refinement/connectToggleRefinement";
import { useSearchParams } from "next/navigation";

import {
	Configure,
	InstantSearch,
	useConnector,
	useCurrentRefinements,
	useInfiniteHits,
	useInstantSearch,
	useRefinementList,
	useStats,
	useToggleRefinement,
} from "react-instantsearch";

// import { InstantSearchNext } from "react-instantsearch-nextjs";

import { searchClient as initialSearchClient } from "utils/search-client";
import { HITS_PER_PAGE } from "utils/config";

export {
	Configure,
	Highlight,
} from "react-instantsearch";

type UseClearRefinementsProps =
	ClearRefinementsConnectorParams;

export type UseRangeSliderProps =
	RangeConnectorParams;

export type viewType = "grid" | "list";
export type indexNameType =
	| "products"
	| "products_date_asc"
	| "products_price_asc"
	| "products_price_desc";

interface ISearchContextProps {
	view: viewType;
	toggleView: (
		v: viewType
	) => () => void;
	indexName: indexNameType;
	switchSortBy: (
		v: indexNameType
	) => void;
	useClearRefinements: (
		props?: UseClearRefinementsProps
	) => ClearRefinementsRenderState;
	useRangeSlider: (
		props: UseRangeSliderProps
	) => RangeRenderState;
	useCurrentRefinements: typeof useCurrentRefinements;
	useRefinementList: typeof useRefinementList;
	useInfiniteHits: typeof useInfiniteHits;
	useInstantSearch: typeof useInstantSearch;
	useStats: typeof useStats;
	useToggleRefinement: typeof useToggleRefinement;
}

const SearchContext =
	createContext<ISearchContextProps | null>(
		null
	);

interface SearchProviderProps {
	children?: React.ReactNode;
	allowEmptySearch?: boolean;
	indexName?: indexNameType;
	hitsPerPage?: number;
	query?: string;
	filters?: string;
	facetFilters?: string[];
	routing?: boolean;
	insights?: boolean;
	// enablePersonnalization?: boolean;
}

export const SearchProvider = ({
	children,
	allowEmptySearch = false,
	indexName:
		initialIndexName = "products",
	hitsPerPage = HITS_PER_PAGE,
	query = "",
	filters,
	facetFilters,
	routing = true,
}: // insights = true,
// enablePersonnalization = true,
SearchProviderProps) => {
	const searchParams =
		useSearchParams();

	const [view, setView] =
		useState<viewType>(
			searchParams?.get("view") ===
				"list"
				? "list"
				: "grid"
		);

	const toggleView = useCallback(
		(v: viewType) => () => {
			setView(v as viewType);
			// const params =
			// 	new URLSearchParams(
			// 		searchParams?.toString()
			// 	);

			// 	params.set("view", v);
			// window.history.pushState(
			// 	null,
			// 	"",
			// 	`?${params.toString()}`
			// );
		},
		[
			// setView, searchParams
		]
	);

	const sort_by =
		searchParams?.get("sort_by");

	const [indexName, setIndexName] =
		useState<indexNameType>(
			sort_by
				? (sort_by as indexNameType)
				: initialIndexName
		);

	const switchSortBy = useCallback(
		(v: indexNameType) => {
			setIndexName(v);
			// const params =
			// 	new URLSearchParams(
			// 		searchParams?.toString()
			// 	);
			// params.set("sort_by", v);
			// window.history.pushState(
			// 	null,
			// 	"",
			// 	`?${params.toString()}`
			// );
		},
		[
			// setIndexName,
			// searchParams
		]
	);

	const searchClient = {
		...initialSearchClient,
		search(requests) {
			if (
				requests.every(
					({ params }) => !params.query
				)
			) {
				return Promise.resolve({
					results: requests.map(() => ({
						hits: [],
						nbHits: 0,
						nbPages: 0,
						page: 0,
						processingTimeMS: 0,
						hitsPerPage: 0,
						exhaustiveNbHits: false,
						query: "",
						params: "",
					})),
				});
			}
			return initialSearchClient.search(
				requests
			);
		},
	};

	function useClearRefinements(
		props: UseClearRefinementsProps
	) {
		return useConnector<
			ClearRefinementsConnectorParams,
			ClearRefinementsWidgetDescription
		>(connectClearRefinements, props);
	}
	function useRangeSlider(
		props?: UseRangeSliderProps
	) {
		return useConnector<
			RangeConnectorParams,
			RangeWidgetDescription
		>(connectRange, props);
	}

	const routinghandler = {
		stateMapping: {
			stateToRoute(uiState) {
				const indexUiState =
					uiState[indexName] ?? {};

				return {
					searchQuery:
						indexUiState.configure
							?.query.length > 0
							? indexUiState.configure
									?.query
							: undefined,
					sort_by:
						indexUiState.configure
							?.index,
					view,
					size: indexUiState
						.refinementList?.size,
					color:
						indexUiState.refinementList
							?.color,
					brand:
						indexUiState.refinementList
							?.brand,
					season:
						indexUiState.refinementList
							?.season,
					condition:
						indexUiState.refinementList
							?.condition,
					section:
						indexUiState.refinementList
							?.section,
					main_sections:
						indexUiState.refinementList
							?.main_sections,
					material:
						indexUiState.refinementList
							?.material,
					price:
						indexUiState.range
							?.calculated_price,

					// in_stock:
					// 	indexUiState.toggle
					// 		?.in_stock,
					// is_sale:
					// 	indexUiState.toggle
					// 		?.is_sale,
				};
			},
			routeToState(routeState) {
				return {
					[routeState.sort_by]: {
						refinementList: {
							size: routeState.size,
							color: routeState.color,
							brand: routeState.brand,
							season: routeState.season,
							condition:
								routeState.condition,
							section:
								routeState.section,
							main_sections:
								routeState.main_sections,
							material:
								routeState.material,
						},
						range: {
							calculated_price:
								routeState.price,
						},
						// toggle: {
						// 	in_stock:
						// 		routeState.in_stock,
						// 	is_sale:
						// 		routeState.is_sale,
						// },
					},
				};
			},
		},
	};

	return (
		<SearchContext.Provider
			value={{
				view,
				toggleView,
				indexName,
				switchSortBy,
				useClearRefinements,
				useRangeSlider,
				useCurrentRefinements,
				useRefinementList,
				useInfiniteHits,
				useInstantSearch,
				useStats,
				useToggleRefinement,
			}}>
			<InstantSearch
				searchClient={
					allowEmptySearch
						? initialSearchClient
						: (searchClient as typeof initialSearchClient)
				}
				indexName={indexName}
				routing={
					routing
						? routinghandler
						: false
				}
				future={{
					preserveSharedStateOnUnmount:
						false,
					persistHierarchicalRootCount:
						false,
				}}
				stalledSearchDelay={500}>
				<Configure
					hitsPerPage={hitsPerPage}
					query={query}
					filters={filters}
					facetFilters={facetFilters}
					index={indexName}
					clickAnalytics
				/>

				{children}
			</InstantSearch>
		</SearchContext.Provider>
	);
};

export const useSearch = () => {
	const context = useContext(
		SearchContext
	);

	if (context === null) {
		throw new Error(
			"useSearch must be used within a SearchProvider"
		);
	}
	return context;
};
