"use client";
import { Container } from "@mui/material";
import SearchTextField from "components/SearchTextField";
import CustomDialog from "components/dialog-and-drawer/CustomDialog";
import HeaderIconButton from "components/header/HeaderIconButton";
import Search from "components/icons/Search";
import {
	usePathname,
	useSearchParams,
} from "next/navigation";
import React, {
	FC,
	useEffect,
	useState,
} from "react";
import { cdpClient } from "utils/analytics";
import SearchInputWithCategory from "components/search-box/SearchInputWithCategory";
import useDeviceSize from "hooks/useDeviceSize";

type SearchTriggerProps = {
	openerVariant: "button" | "input";
	toggleDialog: (
		state: boolean
	) => void;
};

const SearchTrigger: React.FC<
	SearchTriggerProps
> = ({
	openerVariant,
	toggleDialog,
}) => {
	const handleButtonClick = () => {
		toggleDialog(true);
		cdpClient?.track(
			"Header Search Icon Button Clicked",
			{
				eventType: "click",
				category: "Search",
				label: "Header Search",
			}
		);
	};

	if (openerVariant === "button") {
		return (
			<HeaderIconButton
				icon={<Search />}
				onClick={handleButtonClick}
				aria-label='recherche'
				title='Recherche'
			/>
		);
	}

	const handleSearchInputClick = () => {
		toggleDialog(true);
		cdpClient?.track(
			"Search drawer opened via search input",
			{
				eventType: "click",
				category: "Search",
				label: "Search drawer",
			}
		);
	};

	return (
		<SearchTextField
			onClick={handleSearchInputClick}
			onFocus={(e) => e.target.blur()}
			inputProps={{ readOnly: true }}
			sx={{
				"& .MuiOutlinedInput-root.Mui-focused":
					{
						"& > fieldset": {
							borderColor: "grey.700",
						},
					},
			}}
		/>
	);
};

type SearchDrawerProps = {
	openerVariant?: "button" | "input";
};

const SearchDrawer: FC<
	SearchDrawerProps
> = ({ openerVariant = "button" }) => {
	const [dialogOpen, setDialogOpen] =
		useState(false);
	const toggleDialog = (
		state: boolean
	) => {
		setDialogOpen(state);
	};

	const pathname = usePathname() || "";

	const searchQuery =
		useSearchParams()?.get(
			"searchQuery"
		) || "";

	const prevSearchQuery = React.useRef(
		searchQuery
	);

	useEffect(() => {
		const handleRouteChange = () => {
			if (
				prevSearchQuery.current ===
					searchQuery ||
				!dialogOpen
			)
				return;
			prevSearchQuery.current =
				searchQuery;
			toggleDialog(false);
		};
		handleRouteChange();
	}, [
		dialogOpen,
		pathname,
		searchQuery,
	]);

	const { upLg } = useDeviceSize();

	return (
		<>
			<SearchTrigger
				openerVariant={openerVariant}
				toggleDialog={toggleDialog}
			/>
			<CustomDialog
				toggleDialog={toggleDialog}
				dialogOpen={dialogOpen}
				title={"Recherche"}
				fullscreen={!upLg}
				hasHeader={false}
				isCenter={false}>
				<Container
					sx={{
						width: "auto",
						py: 2,
						height: upLg
							? "60vh"
							: undefined,
					}}>
					<SearchInputWithCategory
						showCategoryDropdown
						forDrawer
						toggleDrawer={toggleDialog}
					/>
				</Container>
			</CustomDialog>
		</>
	);
};

export default SearchDrawer;
