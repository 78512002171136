import SkeletonIconButton from "components/SkeletonIconButton";
import { useAccount } from "contexts/AccountContext";
import useDeviceSize from "hooks/useDeviceSize";
import useSettings from "hooks/useSettings";
import dynamic from "next/dynamic";
import { FC } from "react";
import HeaderActionsWrapper from "./HeaderActionsWrapper";
// ==============================================================
type HeaderRightActionsProps = {
	showSearchDrawer?: boolean;
};
// ==============================================================

const DynamicSearchDrawer = dynamic(
	() =>
		import(
			"components/dialog-and-drawer/SearchDrawer"
		),
	{
		ssr: false,
	}
);

const DynamicUserAuthPopover = dynamic(
	() =>
		import(
			"components/header/UserAuthPopover"
		),
	{
		ssr: false,
		loading: () => (
			<SkeletonIconButton />
		),
	}
);

const DynamicWishlistAuthPopover =
	dynamic(
		() =>
			import(
				"components/header/WishListAuthPopover"
			),
		{
			ssr: false,
			loading: () => (
				<SkeletonIconButton />
			),
		}
	);

const DynamicCartPopover = dynamic(
	() =>
		import(
			"pages-sections/cart/CartPopover"
		),
	{
		ssr: false,
		loading: () => (
			<SkeletonIconButton />
		),
	}
);

const HeaderRightActions: FC<
	HeaderRightActionsProps
> = ({ showSearchDrawer }) => {
	const {
		retrievingCustomer,
		customer,
	} = useAccount();

	const { downMd } = useDeviceSize();

	const {
		settings: {
			is_wishlist_enabled:
				isWishlistEnabled,
		},
	} = useSettings();

	return (
		<HeaderActionsWrapper>
			{downMd && showSearchDrawer && (
				<DynamicSearchDrawer />
			)}
			{retrievingCustomer ? (
				<>
					<SkeletonIconButton />
					{showSearchDrawer && (
						<SkeletonIconButton />
					)}
					{isWishlistEnabled && (
						<SkeletonIconButton />
					)}
					<SkeletonIconButton />
				</>
			) : (
				<>
					<DynamicUserAuthPopover />
					{isWishlistEnabled && (
						<DynamicWishlistAuthPopover
							isCustomer={!!customer}
						/>
					)}
					<DynamicCartPopover />
				</>
			)}
		</HeaderActionsWrapper>
	);
};

export default HeaderRightActions;
