import {
	Container,
	ContainerProps,
} from "@mui/material";

const MobileExtraHeaderContainer = (
	props: ContainerProps
) => {
	return (
		<Container
			{...props}
			sx={{
				...props.sx,
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				gap: 2,
			}}
		/>
	);
};

export default MobileExtraHeaderContainer;
