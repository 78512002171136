"use client";
import { CardProps } from "@mui/material";
import BazaarCard from "components/BazaarCard";
import { layoutConstant } from "utils/constants";

const MobileExtraHeaderWrapper = (
	props: CardProps & {
		border: number;
	}
) => {
	return (
		<BazaarCard
			{...props}
			sx={{
				...props.sx,
				height: "60px",
				width: "100%",
				borderRadius: "0px",
				position: "relative",
				...(props.border && {
					borderBottom: (theme) =>
						`1px solid ` +
						theme.palette.grey[200],
				}),
				display: {
					...layoutConstant.regularLayoutMobileExtraHeaderDisplay,
				},
			}}
		/>
	);
};

export default MobileExtraHeaderWrapper;
