"use client";

import useDeviceSize from "hooks/useDeviceSize";
import { FC } from "react";
import { InstantSearch } from "react-instantsearch";
import { searchClient as initialSearchClient } from "utils/search-client";
import SearchBox from "./SearchBox";

export interface SearchInputWithCategoryProps {
	showCategoryDropdown?: boolean;
	forDrawer?: boolean;
	toggleDrawer?: (
		state: boolean
	) => void;
}

const emptySearchResults = {
	hits: [],
	nbHits: 0,
	nbPages: 0,
	page: 0,
	processingTimeMS: 0,
	hitsPerPage: 0,
	exhaustiveNbHits: false,
	query: "",
	params: "",
};

const SearchInputWithCategory: FC<
	SearchInputWithCategoryProps
> = ({
	showCategoryDropdown = true,
	forDrawer = false,
	toggleDrawer,
}) => {
	const { upMd } = useDeviceSize();

	const searchClient = {
		...initialSearchClient,
		search(requests) {
			Promise.resolve({
				results: requests.map(
					() => emptySearchResults
				),
			});
			return initialSearchClient.search(
				requests
			);
		},
	};

	const effectiveSearchClient =
		!upMd || forDrawer
			? initialSearchClient
			: searchClient;

	return (
		<InstantSearch
			indexName='products_query_suggestions'
			searchClient={
				effectiveSearchClient as typeof initialSearchClient
			}>
			<SearchBox
				showCategoryDropdown={
					showCategoryDropdown
				}
				forDrawer={forDrawer}
				toggleDrawer={toggleDrawer}
			/>
		</InstantSearch>
	);
};

export default SearchInputWithCategory;
