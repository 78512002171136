import { styled } from "@mui/material";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
	AnchorHTMLAttributes,
	CSSProperties,
	FC,
} from "react";

// component props interface
export interface NavLinkProps
	extends AnchorHTMLAttributes<HTMLAnchorElement> {
	href: string;
	style?: CSSProperties;
	className?: string;
}
// styled component
const StyledLink = styled(Link)<{
	active_route?: string;
}>(({ theme, active_route }) => ({
	position: "relative",
	transition: "color 150ms ease-in-out",
	color:
		active_route === "active"
			? theme.palette.primary.main
			: "inherit",
	"&:hover": {
		color: `${theme.palette.primary.main} !important`,
	},
}));

const NavLink: FC<NavLinkProps> = ({
	href,
	children,
	style,
	className,
	...props
}) => {
	const pathname = usePathname();

	const checkRouteMatch = () => {
		if (href === "/")
			return pathname === href;
		return pathname?.includes(href);
	};
	// active route
	const currentRoute =
		checkRouteMatch();

	return (
		<StyledLink
			href={href}
			style={style}
			className={clsx(className)}
			active_route={
				currentRoute ? "active" : ""
			}
			{...props}>
			{children}
		</StyledLink>
	);
};

export default NavLink;
