import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	SwipeableDrawer,
	styled,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Scrollbar from "components/Scrollbar";
import { H5 } from "components/Typography";
import {
	FC,
	ReactNode,
	cloneElement,
} from "react";
// styled component
const Wrapper = styled(Box)({
	"& .handle": { cursor: "pointer" },
});

// ================================================================
type SidenavProps = {
	open: boolean;
	width?: number | string;
	maxWidth?: number | string;
	children: ReactNode;
	handle: React.ReactElement;
	toggleSidenav: (
		state: Boolean
	) => void;
	position?: "left" | "right";
	title?: string;
};
// ================================================================

const Sidenav: FC<SidenavProps> = (
	props
) => {
	const {
		position = "left",
		open = false,
		width = 280,
		maxWidth,
		handle,
		children,
		toggleSidenav,
		title = "",
	} = props;

	return (
		<Wrapper>
			<SwipeableDrawer
				anchor={position}
				open={open}
				onClose={() =>
					toggleSidenav(false)
				}
				onOpen={() =>
					toggleSidenav(true)
				}
				SlideProps={{
					style: { width, maxWidth },
				}}
				sx={{ zIndex: 15001 }}
				keepMounted>
				<AppBar
					sx={{
						position: "relative",
						padding: 2,
					}}
					color='transparent'>
					<Toolbar
						variant='dense'
						disableGutters
						sx={{
							justifyContent:
								"space-between",
						}}>
						<H5>{title}</H5>
						<IconButton
							onClick={() => {
								toggleSidenav(false);
							}}
							aria-label='close'
							size='small'>
							<CloseIcon fontSize='medium' />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Divider />
				<Scrollbar autoHide={false}>
					{children}
				</Scrollbar>
			</SwipeableDrawer>

			{handle &&
				cloneElement(handle, {
					onClick: toggleSidenav,
					className: clsx(
						handle.props?.className,
						"handle"
					),
				})}
		</Wrapper>
	);
};

export default Sidenav;
