import {
	FormControl,
	Input,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { FlexBox } from "components/flex-box";
import React from "react";
import { actualSectionType } from "./SearchBox";

type SelectSectionProps = {
	sections: actualSectionType[];
	actualSection: actualSectionType | null;
	handleCategoryChange: (
		cat: actualSectionType
	) => () => void;
	open: boolean;
	setOpen: React.Dispatch<
		React.SetStateAction<boolean>
	>;
};

// ================================================================

const SelectSection: React.FC<
	SelectSectionProps
> = ({
	sections,
	actualSection,
	handleCategoryChange,
	open,
	setOpen,
}) => {
	const selectPlaceholder = "Section";

	const handleSelectChange = (
		event: SelectChangeEvent<string>
	) => {
		const {
			target: { value },
		} = event;
		const selectedSection =
			sections.find(
				(section) =>
					section.title === value
			);
		if (selectedSection) {
			handleCategoryChange(
				selectedSection
			)();
			setOpen(false);
		}
	};

	return (
		<div>
			<FormControl
				sx={{
					width: "100%",
					zIndex: (theme) =>
						theme.zIndex.drawer + 1000,
				}}>
				<Select
					// MUI5 warning about non boolean attribute workaround
					//  see : https://github.com/mui/material-ui/issues/38512
					notched={undefined}
					displayEmpty
					value={
						actualSection?.title ?? ""
					}
					onChange={handleSelectChange}
					input={<Input />}
					renderValue={(selected) => {
						if (!selected) {
							return (
								<>{selectPlaceholder}</>
							);
						}
						return selected;
					}}
					inputProps={{
						"aria-label":
							"Selectionnez la section de votre choix",
					}}
					MenuProps={{
						disablePortal: true,
					}}
					open={open}
					onClose={() => {
						setOpen(false);
					}}
					onOpen={() => {
						setOpen(true);
					}}>
					{sections.map(
						(section, i) => {
							const { value, title } =
								section;
							return (
								<MenuItem
									key={value}
									value={title}
									divider={
										i <
										sections.length - 1
									}>
									<ListItemText
										primary={
											<FlexBox
												alignItems='center'
												justifyContent='flex-start'>
												{title}
											</FlexBox>
										}
									/>
								</MenuItem>
							);
						}
					)}
				</Select>
			</FormControl>
		</div>
	);
};

export default SelectSection;
