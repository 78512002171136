"use client";

import Header from "components/header/Header";
import {
	useCallback,
	useState,
} from "react";

import Sticky from "components/Sticky";

const ConditionalFixedHeader = ({
	isSticky,
}: {
	isSticky: boolean;
}) => {
	const [isFixed, setIsFixed] =
		useState(false);
	const toggleIsFixed = useCallback(
		(fixed: boolean) =>
			setIsFixed(fixed),
		[]
	);
	if (!isSticky)
		return <Header isFixed={isFixed} />;
	return (
		<Sticky
			fixedOn={0}
			onSticky={toggleIsFixed}
			scrollDistance={300}>
			<Header isFixed={isFixed} />
		</Sticky>
	);
};

export default ConditionalFixedHeader;
