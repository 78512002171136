"use client";
import {
	Autocomplete,
	// Backdrop,
	Box,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	createFilterOptions,
} from "@mui/material";

import {
	usePathname,
	useRouter,
	useSearchParams,
} from "next/navigation";
import {
	ChangeEvent,
	FC,
	useEffect,
	useState,
	useTransition,
} from "react";
import {
	ClearIcon,
	DeleteIcon,
	GroupHeader,
	GroupItems,
	HistoryOutlinedIcon,
	SearchOutlinedIcon,
	StyledHighlight,
	TrendingUpOutlinedIcon,
} from "./styled";

import SearchTextField from "components/SearchTextField";
import { Small } from "components/Typography";
import { useNavigation } from "contexts/NavigationContext";
// import useFashionPreference from "hooks/useFashionPreference";
import useSettings from "hooks/useSettings";
import {
	Configure,
	useHits,
	useSearchBox,
} from "react-instantsearch";
import { cdpClient } from "utils/analytics";
import {
	CATALOG_NAME,
	SECTION_ROOT,
} from "utils/config";
import {
	RecentSearchType,
	addRecentSearch,
	deleteRecentSearch,
	getRecentSearches,
} from "utils/recentSearches";
import SelectSection from "./SelectSection";

type optionType = {
	id: string;
	query: string;
	origin:
		| "recent"
		| "suggestions"
		| "trending"
		| "userInput";
} & typeof StyledHighlight.prototype.props.hit;

export type actualSectionType = {
	title: string;
	value: string;
	path?: string | null;
};

export interface SearchBoxProps {
	showCategoryDropdown?: boolean;
	forDrawer?: boolean;
	toggleDrawer?: (
		state: boolean
	) => void;
}

const filter = createFilterOptions();

const SearchBox: FC<SearchBoxProps> = ({
	showCategoryDropdown = true,
	forDrawer = false,
	toggleDrawer,
}) => {
	const router = useRouter();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, startTransition] =
		useTransition();

	const { mainSections } =
		useNavigation();

	// const { fashionPreference } =
	// 	useFashionPreference();

	const [
		actualSection,
		setActualSection,
	] =
		useState<actualSectionType | null>(
			// null
			// set default section to all
			{
				title: "Tout",
				value: CATALOG_NAME,
				path: "",
			}
		);

	const [isActive, setIsActive] =
		useState(false);

	// we are setting all as default section allways only if user chooses
	//  a section we will update the section
	// useEffect(() => {
	// 	if (!fashionPreference) {
	// 		setActualSection({
	// 			title: "Tout",
	// 			value: CATALOG_NAME,
	// 			path: "",
	// 		});
	// 		return;
	// 	}
	// 	setActualSection({
	// 		title: fashionPreference?.name,
	// 		value: fashionPreference?.handle,
	// 		path: fashionPreference?.hierarchyPath,
	// 	});
	// }, [fashionPreference]);

	const updatedMainSections = [
		{
			title: "Tout",
			value: CATALOG_NAME,
			path: "",
		},
		...(mainSections ?? []).flatMap(
			(item) => ({
				title: item.name,
				value: item.handle,
				path: item.hierarchyPath,
			})
		),
	];

	//  merge sections with main sections

	const sections = [
		...(updatedMainSections ?? []),
	]
		.filter(
			(section, index, self) =>
				index ===
				self.findIndex(
					(t) =>
						t.title === section.title &&
						t.value === section.value &&
						t.path === section.path
				)
		)
		.flat();

	// HANDLE CHANGE THE section
	const handleCategoryChange =
		(cat: actualSectionType) => () => {
			setActualSection(cat);
			setIsActive(true);
		};

	// HANDLE CHANGE THE QUERY

	const { query, refine: setQuery } =
		useSearchBox();

	const { hits } = useHits();

	const [
		recentSearches,
		setRecentSearches,
	] = useState<RecentSearchType[]>(
		getRecentSearches()?.map(
			(item: RecentSearchType) => ({
				id: item.id as string,
				query: item.query as string,
				section: item.section,
				origin: "recent",
			})
		)
	);

	const handleDeleteRecentSearch = (
		query: string,
		section: actualSectionType
	) => {
		deleteRecentSearch({
			query,
			section,
		});
		// update state to reflect the deletion
		setRecentSearches(
			getRecentSearches().map(
				(item) => ({
					id: item.id as string,
					query: item.query as string,
					section: item.section,
					origin: "recent",
				})
			)
		);
	};

	const { settings } = useSettings();

	const handleAddRecentSearch = (
		query: string,
		section: actualSectionType
	) => {
		if (
			!settings?.privacy_consent
				?.personnalization
		)
			return;
		addRecentSearch({ query, section });
		// update state to reflect the addition
		setRecentSearches(
			getRecentSearches().map(
				(item) => ({
					id: item.id as string,
					query: item.query as string,
					section: item.section,
					origin: "recent",
				})
			)
		);
	};

	const [
		trendingSearches,
		setTrendingSearches,
	] = useState<optionType[]>([]);

	const [suggestions, setSuggestions] =
		useState<optionType[]>([]);

	useEffect(() => {
		if (!query) {
			setTrendingSearches(
				hits.map((item) => ({
					id: item.objectID as string,
					query: item.query as string,
					origin: "trending",
					...item,
				})) as unknown as optionType[]
			);
		} else if (query) {
			setSuggestions(
				hits.map((item) => ({
					id: item.objectID as string,
					query: item.query as string,
					origin: "suggestions",
					...item,
				})) as unknown as optionType[]
			);
		}
	}, [query, hits]);

	const createSearchPath = ({
		origin,
		section,
		// query,
		valueSection,
	}: {
		origin: string;
		section: actualSectionType;
		// query: string,
		valueSection?: actualSectionType;
	}) => {
		const value =
			origin !== "recent"
				? section?.value
				: valueSection?.value;
		if (value === CATALOG_NAME)
			return `/${value}`;
		return `${SECTION_ROOT}/${value}`;
	};

	const getQueryAndOrigin = (
		value:
			| string
			| Record<string, unknown>
	) => {
		return typeof value === "string"
			? {
					query: value,
					origin: "userInput",
			  }
			: {
					query: value.query as string,
					origin:
						value.origin as string,
			  };
	};

	const handleSearch = (
		e: ChangeEvent<HTMLInputElement>,
		value: string | any
	) => {
		startTransition(() => {
			if (!value || !actualSection) {
				return;
			}

			const { query, origin } =
				getQueryAndOrigin(value);

			// track with segment

			cdpClient?.track(
				"Search Box Used",
				{
					query,
					origin,
					section: actualSection?.value,
					eventType: "click",
					category: "Search",
					label: "Search Box",
				}
			);

			handleAddRecentSearch(
				query,
				origin !== "recent"
					? {
							title:
								actualSection?.title,
							value:
								actualSection?.value,
							path: actualSection?.path,
					  }
					: {
							title:
								value.section?.title,
							value:
								value.section?.value,
							path: value.section?.path,
					  }
			);

			const searchPath =
				createSearchPath({
					origin,
					section: actualSection,
					// query,
					valueSection: value.section,
				});

			const searchUrl = `${searchPath}?${new URLSearchParams(
				{
					searchQuery: query,
				}
			).toString()}`;
			forDrawer &&
				toggleDrawer &&
				toggleDrawer(false);
			setQuery("");
			router.push(searchUrl);
		});
	};

	const handleOnOpen = () => {
		if (!actualSection) {
			!openSelectSection &&
				setOpenSelectSection(true);
			return;
		}
		setIsActive(true);
		cdpClient?.track(
			"Search Box Opened",
			{
				section: actualSection?.value,
				eventType: "click",
				category: "Search",
				label: "Search Box",
			}
		);
	};

	const handleOnClose = () => {
		setIsActive(false);
		cdpClient?.track(
			"Search Box Closed",
			{
				section: actualSection?.value,
				eventType: "click",
				category: "Search",
				label: "Search Box",
			}
		);
	};

	const facetFilters =
		(actualSection?.path?.length ?? 0) >
		0
			? [
					`products.facets.exact_matches.hierarchical_section.value:${actualSection?.path}`,
			  ]
			: [];

	const [
		openSelectSection,
		setOpenSelectSection,
	] = useState<boolean>(false);

	const pathname = usePathname();
	const searchQuery =
		useSearchParams()?.get(
			"searchQuery"
		);

	useEffect(() => {
		setIsActive(false);
		setOpenSelectSection(false);
	}, [pathname, setQuery, searchQuery]);

	return (
		<>
			<Configure
				hitsPerPage={5}
				facetFilters={facetFilters}
			/>
			<Box
				position='relative'
				flex='1 1 0'
				mx='auto'
				minWidth='200px'>
				<Autocomplete
					sx={{
						width: "100%",
						bgcolor: "background.paper",
					}}
					freeSolo
					disablePortal
					handleHomeEndKeys
					open={
						(isActive || forDrawer) &&
						!openSelectSection
					}
					onOpen={handleOnOpen}
					onClose={handleOnClose}
					PaperComponent={({
						children,
					}) => (
						<Paper
							elevation={0}
							sx={{
								borderTopRightRadius: 0,
								borderTopLeftRadius: 0,
							}}>
							{children}
						</Paper>
					)}
					ListboxProps={{
						sx: {
							maxHeight: "60vh",
						},
						id: "search-box-list",
						role: "listbox",
						"aria-labelledby":
							"search-box-list",
						"aria-label":
							"search-box-list",
					}}
					clearIcon={
						<ClearIcon
							color='inherit'
							fontSize='small'
							titleAccess='Clear search'
							//  make mouse as pointer
							sx={{ cursor: "pointer" }}
						/>
					}
					inputValue={query}
					onInputChange={(
						event,
						newInputValue
					) => {
						if (!actualSection) return;
						setQuery(newInputValue);
					}}
					onChange={handleSearch}
					options={
						query
							? suggestions
							: recentSearches.length >
							  0
							? recentSearches
							: trendingSearches
					}
					groupBy={(
						option: optionType
					) =>
						query
							? ""
							: option.origin ===
							  "recent"
							? "Recherches récentes"
							: option.origin ===
							  "trending"
							? "Tendances"
							: ""
					}
					renderGroup={(params) => (
						<div key={params.key}>
							<GroupHeader>
								{params.group}
							</GroupHeader>
							<GroupItems role='list'>
								{params.children}
							</GroupItems>
						</div>
					)}
					getOptionLabel={(
						option: optionType
					) => {
						// Value selected with enter, right from the input
						if (
							typeof option === "string"
						) {
							return option;
						}
						// Regular option
						return option.query as string;
					}}
					filterOptions={(
						options,
						params
					) => {
						const filtered = filter(
							options,
							params
						);

						const { inputValue } =
							params;

						// Suggest the creation of a new value
						const isExisting =
							options.some(
								(option: optionType) =>
									option.query ===
									inputValue
							);
						if (
							inputValue !== "" &&
							!isExisting
						) {
							filtered.unshift({
								id: Math.random(),
								query: inputValue,
								origin: "userInput",
							});
						}

						return filtered;
					}}
					renderInput={(params) => (
						<SearchTextField
							id='search-box-input'
							passedRef={
								params.InputProps.ref
							}
							placeholder={
								!!actualSection
									? `Recherche de produits dans ${actualSection?.title}...`
									: "Recherche de produits..."
							}
							onBlur={() => {
								if (openSelectSection)
									return;
								setIsActive(false);
							}}
							autoFocus={
								(isActive ||
									forDrawer) &&
								!openSelectSection
							}
							onFocus={() => {
								//  if !actualsection don't focus
								if (
									!actualSection?.value
								) {
									return;
								}
								if (
									!openSelectSection
								) {
									setIsActive(true);
								}
							}}
							fullWidth
							variant='outlined'
							InputProps={{
								sx: {
									zIndex: (theme) =>
										isActive ||
										openSelectSection
											? theme.zIndex
													.drawer + 1
											: 1,
									bgcolor:
										"background.paper",
								},
								startAdornment:
									showCategoryDropdown &&
										(isActive ||
											openSelectSection ||
											forDrawer) && (
											<SelectSection
												sections={
													sections
												}
												actualSection={
													actualSection
												}
												handleCategoryChange={
													handleCategoryChange
												}
												open={
													openSelectSection
												}
												setOpen={
													setOpenSelectSection
												}
											/>
										),
								endAdornment: (
									<IconButton
										aria-label='Rechercher'
										title='Rechercher'
										onClick={() => {
											handleSearch(
												{} as ChangeEvent<HTMLInputElement>,
												query
											);

											setIsActive(
												false
											);
										}}>
										<SearchOutlinedIcon />
									</IconButton>
								),
							}}
							inputProps={{
								...params.inputProps,
								"aria-label":
									"Recherche",
							}}
						/>
					)}
					renderOption={(
						props,
						option: optionType
					) => {
						const {
							id,
							query,
							origin,
							...hit
						} = option;
						const isRecentSearch =
							origin === "recent";
						const isTrendingSearch =
							origin === "trending";
						const isUserInput =
							origin === "userInput";

						const key =
							id + query + origin;
						return (
							<ListItem
								{...props}
								key={key}
								secondaryAction={
									isRecentSearch && (
										<IconButton
											edge='end'
											aria-label='supprimer'
											title="Supprimer l'historique"
											onClick={(
												event
											) => {
												event.stopPropagation();
												handleDeleteRecentSearch(
													query,
													{
														...hit.section,
													}
												);
											}}>
											<DeleteIcon />
										</IconButton>
									)
								}>
								<ListItemIcon>
									{isRecentSearch ? (
										<HistoryOutlinedIcon />
									) : isTrendingSearch ? (
										<TrendingUpOutlinedIcon />
									) : (
										<SearchOutlinedIcon />
									)}
								</ListItemIcon>
								<ListItemText
									primary={
										isRecentSearch ||
										isUserInput ? (
											query
										) : (
											<StyledHighlight
												attribute='query'
												hit={hit}
											/>
										)
									}
									secondary={
										isRecentSearch &&
										hit?.section
											?.title && (
											<Small color='text.secondary'>
												{"Dans: " +
													hit?.section
														?.title}
											</Small>
										)
									}
									primaryTypographyProps={{
										variant:
											"subtitle1",
										color:
											"text.primary",
										fontWeight:
											isRecentSearch ||
											isUserInput
												? "bold"
												: undefined,
										component: "div",
									}}
								/>
							</ListItem>
						);
					}}
				/>

				{/* <Backdrop
					open={
						(isActive ||
							openSelectSection) &&
						!forDrawer
					}
					sx={{
						color: "#fff",
						backgroundColor:
							"rgba(0, 0, 0, 0.5)",
						zIndex: (theme) =>
							theme.zIndex.drawer,
					}}
				/> */}
			</Box>
		</>
	);
};
export default SearchBox;
