import {
	useCart,
	useShippingOptions,
} from "medusa-react";

export const useAvailableShippingOptions =
	({
		isReturn = false,
	}: {
		isReturn?: boolean;
	}) => {
		const { cart } = useCart();
		const {
			shipping_options:
				availableShippingOptions,
		} = useShippingOptions({
			region_id: cart?.region?.id!,
			is_return: isReturn
				? "true"
				: "false",
		});

		return availableShippingOptions;
	};
