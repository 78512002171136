import Skeleton from "@mui/material/Skeleton";
import { FC } from "react";
interface SkeletonIconButtonProps {
  skeletonSize?: number;
  variant?:
    | "circular"
    | "rectangular"
    | "text"
    | "rounded";
}
const SkeletonIconButton: FC<
  SkeletonIconButtonProps
> = ({
  skeletonSize = 40,
  variant = "circular",
}) => {
  return (
    <Skeleton
      variant={variant}
      width={skeletonSize}
      height={skeletonSize}
    />
  );
};

export default SkeletonIconButton;
