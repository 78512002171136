"use client";
import {
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { FlexBetween } from "components/flex-box";
import { useAccount } from "contexts/AccountContext";
import {
	usePathname,
	useRouter,
} from "next/navigation";
import {
	ACCOUNT_CLEANOUT_ROOT,
	CLEANOUT_ROOT,
} from "utils/config";

const SellAndBuySwitch = ({
	size = "medium",
}: {
	size?: "small" | "medium" | "large";
}) => {
	const actualPath = usePathname();

	const isSellActive =
		actualPath?.includes(CLEANOUT_ROOT);

	const router = useRouter();

	const { customer } =
		useAccount() || {};

	return (
		<ToggleButtonGroup
			size={size}
			color='secondary'
			value={
				isSellActive ? "sell" : "buy"
			} // TODO: make it dynamic
			exclusive
			onChange={(e, newValue) => {
				if (newValue === "buy") {
					// navigate to buy page
					router.push("/");
				} else {
					// navigate to sell page
					router.push(
						customer?.has_account
							? ACCOUNT_CLEANOUT_ROOT
							: CLEANOUT_ROOT
					);
				}
			}}
			aria-label='Platform'>
			<ToggleButton
				value='buy'
				selected={!isSellActive}
				sx={{
					color: "text.primary",
				}}>
				Acheter
			</ToggleButton>
			<ToggleButton
				value='sell'
				selected={isSellActive}
				sx={{
					color: "text.primary",
				}}>
				<FlexBetween columnGap={0.5}>
					<div>Vendre</div>
				</FlexBetween>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

export default SellAndBuySwitch;
