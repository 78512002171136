"use client";

import { getFormattedAmount } from "utils/getFormattedAmount";
import { useAvailableShippingOptions } from "./useAvailableShippingOptions";
import { useCart } from "contexts/CartContext";

// This hook retrieves the smallest free shipping threshold across all shipping options.
const useFreeShippingThreshold = (
	variant:
		| "brut"
		| "formatted" = "formatted",
	isReturn = false,
	includeTaxes = true
) => {
	const availableShippingOptions =
		useAvailableShippingOptions({
			isReturn,
		});

	const { cart } = useCart();

	// Find the smallest min_subtotal requirement among all shipping options that offer free shipping
	const thresholds =
		availableShippingOptions
			?.filter(
				(option) => option.amount === 0
			)
			?.map(
				(option) =>
					option?.requirements?.find(
						(req) =>
							req.type ===
							"min_subtotal"
					)?.amount
			)
			?.filter(
				(amount) => amount != null
			);

	let freeShippingThreshold:
		| string
		| number
		| null =
		thresholds && thresholds.length > 0
			? Math.min(
					...(thresholds as number[])
			  )
			: null;

	if (
		variant === "brut" &&
		includeTaxes
	) {
		const taxRate =
			(cart?.region?.tax_rate || 0) /
			100;

		freeShippingThreshold =
			(freeShippingThreshold as number) *
			(1 + taxRate);
	}

	if (variant === "formatted") {
		freeShippingThreshold =
			getFormattedAmount({
				amount:
					freeShippingThreshold || 0,
				region: cart?.region,
				includeTaxes,
			});
	}

	return freeShippingThreshold;
};

export default useFreeShippingThreshold;
