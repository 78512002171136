// import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"

// const endpoint =
//   process.env.NEXT_PUBLIC_SEARCH_ENDPOINT || "http://127.0.0.1:7700"

// const apiKey = process.env.NEXT_PUBLIC_SEARCH_API_KEY || "test_key"

// export const searchClient = instantMeiliSearch(endpoint, apiKey)

// export const SEARCH_INDEX_NAME =
//   process.env.NEXT_PUBLIC_INDEX_NAME || "products"

// If you want to use Algolia instead then uncomment the following lines, and delete the above lines
// you should also install algoliasearch - yarn add algoliasearch

import algoliasearch from "algoliasearch/lite";
import recommend from "@algolia/recommend";

export const appId =
	process.env
		.NEXT_PUBLIC_SEARCH_APP_ID ||
	"test_app_id";

export const apiKey =
	process.env
		.NEXT_PUBLIC_SEARCH_API_KEY ||
	"test_key";

export const searchClient =
	algoliasearch(appId, apiKey);

export const recommendClient =
	recommend(appId, apiKey);

export const SEARCH_INDEX_NAME =
	process.env.NEXT_PUBLIC_INDEX_NAME ||
	"products";

export const RECOMMEND_INDEX_NAME =
	process.env
		.NEXT_PUBLIC_RECOMMEND_INDEX_NAME ||
	"products";
