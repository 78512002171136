import {
	TextField,
	TextFieldProps,
} from "@mui/material";
import { FC } from "react";
import { SearchOutlinedIcon } from "./search-box/styled";
// ==============================================================
type SearchTextFieldProps =
	TextFieldProps & {
		passedRef?: any;
	};
// ==============================================================
const SearchTextField: FC<
	SearchTextFieldProps
> = ({ passedRef, ...props }) => {
	return (
		<>
			<TextField
				ref={passedRef}
				fullWidth
				variant='outlined'
				placeholder='Recherche Pour...'
				{...props}
				InputProps={{
					...props.InputProps,
					type: "search",
					sx: {
						...(props.InputProps?.sx ??
							{}),
						height: 44,
						// borderRadius: 300,
						color: "grey.700",
						overflow: "hidden",
						"&:hover .MuiOutlinedInput-notchedOutline":
							{
								borderColor:
									"primary.main",
							},
					},
					endAdornment: (
						<SearchOutlinedIcon
							sx={{
								cursor: "pointer",
							}}
						/>
					),
				}}
				inputProps={{
					...props.inputProps,
				}}
			/>
		</>
	);
};

export default SearchTextField;
