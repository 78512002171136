"use client";

import { styled } from "@mui/material";

import Link from "next/link";

// styled component
export const FooterStyledLink = styled(
	Link
)(({ theme }) => ({
	display: "block",
	borderRadius: 4,
	cursor: "pointer",
	position: "relative",
	padding: "0.3rem 0rem",
	color: theme.palette.grey[500],
	"&:hover": {
		color: theme.palette.grey[100],
	},
}));
