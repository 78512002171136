import { Small } from "components/Typography";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FC } from "react";

// ==============================================================
type NavLinkProps = {
	title: string;
	url?: string;
	color?: string;
	borderColor?: string;
};
// ==============================================================

const NavLink2: FC<NavLinkProps> = ({
	url,
	title = "SHOP NOW",
	color,
	borderColor = "secondary.600",
}) => {
	const actualPath = usePathname();
	const isActive = actualPath === url;
	return url ? (
		<Link href={url}>
			<Small
				fontWeight={
					isActive ? "900" : "500"
				}
				borderBottom={isActive ? 2 : 0}
				color={color}
				borderColor={borderColor}>
				{title}
			</Small>
		</Link>
	) : (
		<Small
			fontWeight={
				isActive ? "900" : "500"
			}
			borderBottom={isActive ? 2 : 0}
			color={color}
			borderColor={borderColor}>
			{title}
		</Small>
	);
};

export default NavLink2;
