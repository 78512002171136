import {
	Box,
	BoxProps,
} from "@mui/material";
import Container, {
	ContainerProps,
} from "@mui/material/Container";
import clsx from "clsx";
import Logo from "components/Logo";
import SearchDrawer from "components/dialog-and-drawer/SearchDrawer";
import {
	FlexBetween,
	FlexBox,
} from "components/flex-box";
import SearchInputWithCategory from "components/search-box/SearchInputWithCategory";
import useDeviceSize from "hooks/useDeviceSize";
import { FC } from "react";
import { layoutConstant } from "utils/constants";
import HeaderRightActions from "./HeaderRightActions";
import SellAndBuySwitch from "./SellAndBuySwitch";

// styled components

export const HeaderWrapper = (
	props: BoxProps
) => {
	return (
		<Box
			{...props}
			sx={{
				...props.sx,
				zIndex: 3,
				position: "relative",
				height:
					layoutConstant.headerHeight,
				width: "100%",
				transition:
					"height 250ms ease-in-out",
				background: "background.paper",
				"@media (max-width:600px)": {
					height:
						layoutConstant.mobileHeaderHeight,
				},
			}}
		/>
	);
};

const StyledContainer = (
	props: ContainerProps
) => {
	return (
		<Container
			{...props}
			sx={{
				...props.sx,
				gap: 2,
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		/>
	);
};

// ==============================================================
export type HeaderProps = {
	isFixed?: boolean;
	className?: string;
	searchVariant?: "drawer" | "input";
};
// ==============================================================

const Header: FC<HeaderProps> = ({
	isFixed,
	className,
	searchVariant = "drawer",
}) => {
	const { upMd, upSm } =
		useDeviceSize();
	// FOR SMALLER DEVICE
	if (!upMd) {
		return (
			<HeaderWrapper
				className={clsx(className)}>
				<StyledContainer>
					<FlexBetween columnGap={2}>
						{/* LEFT CONTENT - LOGO */}
						<Logo principal />
						{upSm && (
							<SellAndBuySwitch />
						)}
					</FlexBetween>

					{/* RIGHT CONTENT - LOGIN, CART, SEARCH BUTTON */}
					<HeaderRightActions
						showSearchDrawer={isFixed}
					/>
				</StyledContainer>
			</HeaderWrapper>
		);
	}

	return (
		<HeaderWrapper
			className={clsx(className)}>
			<StyledContainer>
				{/* LEFT CONTENT - LOGO AND CATEGORY */}
				<FlexBox
					mr={2}
					minWidth='64px'
					alignItems='center'>
					<Logo principal />
				</FlexBox>

				<FlexBox
					mr={2}
					minWidth='120px'
					alignItems='center'>
					<SellAndBuySwitch />
				</FlexBox>

				{/* SEARCH FORM */}
				<FlexBox
					justifyContent='center'
					flex='1 1 0'>
					{searchVariant ===
					"drawer" ? (
						<SearchDrawer openerVariant='input' />
					) : (
						<SearchInputWithCategory />
					)}
				</FlexBox>

				{/* LOGIN AND CART BUTTON */}
				<FlexBox
					justifyContent='center'
					sx={{
						paddingLeft: 2,
					}}>
					<HeaderRightActions />
				</FlexBox>
			</StyledContainer>
		</HeaderWrapper>
	);
};

export default Header;
