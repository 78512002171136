import {
  Card,
  styled,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import { HistoryOutlined } from "@mui/icons-material";
import { TrendingUpOutlined } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { Highlight } from "contexts/SearchContext";
import {
  lighten,
  darken,
} from "@mui/system";
import { Paragraph } from "components/Typography";

export const GroupHeader = styled(
  Paragraph
)(({ theme }) => ({
  // position: "sticky",
  top: "-8px",
  padding: "8px 10px",
  color: theme.palette.grey[600],
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(
        theme.palette.primary.light,
        0.85
      )
      : darken(
        theme.palette.primary.main,
        0.8
      ),
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

const styledIcon = (Icon) =>
  styled(Icon)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6,
  }));

export const DeleteIcon =
  styledIcon(Delete);

export const SearchOutlinedIcon =
  styledIcon(SearchOutlined);

export const KeyboardArrowDownOutlinedIcon =
  styledIcon(KeyboardArrowDownOutlined);

export const ClearIcon =
  styledIcon(Clear);

export const HistoryOutlinedIcon =
  styledIcon(HistoryOutlined);

export const TrendingUpOutlinedIcon =
  styledIcon(TrendingUpOutlined);

export const SearchResultCard = styled(
  Card
)({
  zIndex: 99,
  top: "100%",
  width: "100%",
  position: "absolute",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
});

export const StyledHighlight = styled(
  Highlight
)`
  mark {
    background: none;
    font-weight: bold;
  }
`;
