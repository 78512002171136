import { actualSectionType } from "components/search-box/SearchBox";
import handleError from "./handleError";

export type RecentSearchType = {
	id: string;
	query: string;
	section: actualSectionType;
	origin: "recent";
};

const MAX_RECENT_SEARCHES = 10; // Assuming we're capping it at 20

export function addRecentSearch({
	query,
	section,
}: {
	query: string;
	section: actualSectionType;
}): void {
	let recentSearches: RecentSearchType[] =
		getRecentSearches();

	const searchExists =
		recentSearches.some(
			(search) =>
				search.query === query && // checking for exact match (case-sensitive)
				search.section.path ===
					section.path
		);

	if (!searchExists) {
		recentSearches.unshift({
			id: Date.now().toString(),
			query,
			section,
			origin: "recent",
		});

		// Limit the number of recent searches
		if (
			recentSearches.length >
			MAX_RECENT_SEARCHES
		) {
			recentSearches.pop();
		}

		try {
			localStorage.setItem(
				"recentSearches",
				JSON.stringify(recentSearches)
			);
		} catch (error) {
			handleError({
				error,
				logError: true,
			});
		}
	}
}

export function getRecentSearches(): RecentSearchType[] {
	const storedSearches =
		localStorage.getItem(
			"recentSearches"
		);
	return storedSearches
		? JSON.parse(storedSearches)
		: [];
}

export function deleteRecentSearch({
	query,
	section,
}: {
	query: string;
	section: actualSectionType;
}): void {
	let recentSearches: RecentSearchType[] =
		getRecentSearches(); // Retrieve recent searches

	// Filter out the search with the provided query
	recentSearches =
		recentSearches.filter(
			(search) =>
				search.query !== query ||
				search.section.path !==
					section.path
		);

	localStorage.setItem(
		"recentSearches",
		JSON.stringify(recentSearches)
	); // Store back the array
}
