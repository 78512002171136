import {
  IconButton,
  IconButtonProps,
  Badge,
} from "@mui/material";
import {
  FC,
  ReactElement,
} from "react";

type HeaderIconButtonProps = {
  icon: ReactElement;
  badgeContent?: number;
  badgeColor?: "primary" | "secondary";
} & IconButtonProps;

const HeaderIconButton: FC<
  HeaderIconButtonProps
> = ({
  icon,
  badgeColor = "primary",
  badgeContent = 0,
  ...rest
}) => {
  return (
    <IconButton {...rest}>
      <Badge
        badgeContent={badgeContent}
        color={badgeColor}
      >
        {icon}
      </Badge>
    </IconButton>
  );
};

export default HeaderIconButton;
