"use client";

import { FlexBox } from "components/flex-box";
import { NavLink2 } from "components/nav-link";
import { useAccount } from "contexts/AccountContext";
import { FC } from "react";
import {
	ACCOUNT_CLEANOUT_CASHOUT_ROOT,
	ACCOUNT_CLEANOUT_ROOT,
	CLEANOUT_CONSIGNMENT_GUIDE_ROOT,
	CLEANOUT_CONSIGNMENT_ROOT,
	CLEANOUT_ROOT,
} from "utils/config";
import NavbarInnerContainer from "./NavbarInnerContainer";
import NavBarWrapper from "./NavbarWrapper";

// ==========================================================
export type NavbarProps = {
	border?: number;
	elevation?: number;
	navListOpen?: boolean;
	hideCategories?: boolean;
};
// ==========================================================

const DashboardNavbar: FC<
	NavbarProps
> = ({ elevation = 2, border = 3 }) => {
	const { customer } = useAccount();

	const hasConsignmentKits = Boolean(
		customer?.consignments_kits?.length
	);
	return (
		<NavBarWrapper
			elevation={elevation}
			border={border as number}>
			<NavbarInnerContainer>
				<FlexBox gap={2}>
					{hasConsignmentKits && (
						<NavLink2
							url={
								ACCOUNT_CLEANOUT_ROOT
							}
							title='Votre Tableau de Bord'
						/>
					)}
					{sellerNavs.map((nav) => {
						return (
							<NavLink2
								key={nav.title}
								url={nav.href}
								title={nav.title}
							/>
						);
					})}
					{customer?.has_account && (
						<NavLink2
							url={
								ACCOUNT_CLEANOUT_CASHOUT_ROOT
							}
							title="Retirer de l'argent"
						/>
					)}
				</FlexBox>
			</NavbarInnerContainer>
		</NavBarWrapper>
	);
};

export default DashboardNavbar;

export const sellerNavs: {
	title: string;
	href: string;
}[] = [
	{
		title: "Vendre Avec Nous",
		href: CLEANOUT_ROOT,
	},
	{
		title: "Tout Sur Les Gains",
		href: CLEANOUT_CONSIGNMENT_ROOT,
	},
	{
		title: "Guide de Vente",
		href: CLEANOUT_CONSIGNMENT_GUIDE_ROOT,
	},
];
