// "use client";
import MobileMenu from "components/navbar/MobileMenu";
import { FC } from "react";
import HeaderActionsWrapper from "./HeaderActionsWrapper";
// ==============================================================
type MobileExtraHeaderRightActionsProps =
	{};
// ==============================================================

const MobileExtraHeaderRightActions: FC<
	MobileExtraHeaderRightActionsProps
> = () => {
	return (
		<HeaderActionsWrapper>
			<MobileMenu />
		</HeaderActionsWrapper>
	);
};

export default MobileExtraHeaderRightActions;
