"use client";
import { FlexBox } from "components/flex-box";
import { FC } from "react";
// ==============================================================
type HeaderActionsWrapperProps = {
	children?: React.ReactNode;
};
// ==============================================================

const HeaderActionsWrapper: FC<
	HeaderActionsWrapperProps
> = ({ children }) => {
	return (
		<FlexBox
			gap={1.5}
			alignItems='center'>
			{children}
		</FlexBox>
	);
};

export default HeaderActionsWrapper;
