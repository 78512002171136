"use client";

import Home from "components/icons/Home";
import { useAccount } from "contexts/AccountContext";
import { useAuthDialogContext } from "contexts/AuthDialogContext";
import useDeviceSize from "hooks/useDeviceSize";
import { FC } from "react";
import {
	iconStyle,
	StyledNavLink,
	Wrapper,
} from "./styles";
const MobileNavigationBar: FC = () => {
	const { downSm: isMobile } =
		useDeviceSize();
	const { toggle: toggleAuthDialog } =
		useAuthDialogContext();
	const { customer } = useAccount();

	return isMobile ? (
		<Wrapper>
			{list.map((item) => {
				return (
					<StyledNavLink
						href={
							item.restricted &&
							!customer
								? "#"
								: item.href
						}
						key={item.title}
						onClick={() => {
							if (
								item.restricted &&
								!customer
							) {
								toggleAuthDialog();
							}
						}}
						// add accessibility attributes
						aria-label={item.title}
						title={item.title}>
						<item.icon
							sx={iconStyle}
							fontSize='small'
						/>

						{item.title}
					</StyledNavLink>
				);
			})}
		</Wrapper>
	) : null;
};

const list = [
	{
		title: "Home",
		icon: Home,
		href: "/",
		restricted: false,
	},
];

export default MobileNavigationBar;
