"use client";

import SearchDrawer from "components/dialog-and-drawer/SearchDrawer";
import SearchInputWithCategory from "components/search-box/SearchInputWithCategory";
import useDeviceSize from "hooks/useDeviceSize";
import { FC } from "react";
import HeaderActionsWrapper from "./HeaderActionsWrapper";
import MobileExtraHeaderContainer from "./MobileExtraHeaderContainer";
import MobileExtraHeaderRightActions from "./MobileExtraHeaderRightActions";
import MobileExtraHeaderWrapper from "./MobileExtraHeaderWrapper";
import SellAndBuySwitch from "./SellAndBuySwitch";

// ==============================================================
export type MobileExtraHeaderProps = {
	border?: number;
	elevation?: number;
	searchVariant?: "drawer" | "input";
};
// ==============================================================

const RighActionsConditionalWrapper: FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { downSm } = useDeviceSize();
	return downSm ? (
		<HeaderActionsWrapper>
			{children}
		</HeaderActionsWrapper>
	) : (
		<>{children}</>
	);
};

const MobileExtraHeader: FC<
	MobileExtraHeaderProps
> = ({
	border,
	elevation,
	searchVariant = "drawer",
}) => {
	const { downSm } = useDeviceSize();
	return (
		<MobileExtraHeaderWrapper
			// hoverEffect={false}
			elevation={elevation}
			border={border as number}>
			<MobileExtraHeaderContainer>
				{/*  Sell and buy switch */}
				{downSm && (
					<SellAndBuySwitch size='small' />
				)}

				<RighActionsConditionalWrapper>
					{/* SEARCH Drawer */}
					{searchVariant ===
					"drawer" ? (
						<SearchDrawer
							openerVariant={
								downSm
									? "button"
									: "input"
							}
						/>
					) : (
						<SearchInputWithCategory />
					)}
					{/*  NAVIGATION ICON BUTTON */}
					<MobileExtraHeaderRightActions />
				</RighActionsConditionalWrapper>
			</MobileExtraHeaderContainer>
		</MobileExtraHeaderWrapper>
	);
};

export default MobileExtraHeader;
